// import dependency to handle HTTP request to our back end
import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import Service from '@/config/service-identifiers';
import $services from '@/config/container';

export default {
  // TODO: state without paginated/unpaginated declared here (for vue reactivity), better in mutations with Vue.set
  state: {
    events: {
      paginated: {},
      unpaginated: [],
    },
    subscriptions: {},
    payments: {},
    transactions: {
      paginated: {},
      unpaginated: [],
    },
    invoices: {
      paginated: {},
      unpaginated: [],
    },
    shipments: {
      paginated: {},
      unpaginated: [],
    },
    forms: {
      paginated: {},
      unpaginated: [],
    },
    formQuestions: {
      paginated: {},
      unpaginated: [],
    },
    communitiesOthers: {},
    availabilityRules: {
      paginated: {},
      unpaginated: [],
    },
    rooms: {
      paginated: {},
      unpaginated: [],
    },
    parentRooms: {
      paginated: {},
      unpaginated: [],
    },
    posts: {
      paginated: {},
      unpaginated: [],
    },
    communityMembers: {
      paginated: {},
      unpaginated: [],
    },
    communityLikes: {
      paginated: {},
      unpaginated: [],
    },
    completedItems: {
      paginated: {},
      unpaginated: [],
    },
    completableElements: {
      paginated: {},
      unpaginated: [],
    },
    memberRelation: {
      paginated: {},
      unpaginated: [],
    },
    getOfSame: {
      paginated: {},
      unpaginated: [],
    },
    sharedCommunities: {},
    myMemberRelationships: {
      paginated: {},
      unpaginated: [],
    },
    organizationMembers: {},
    organizations: {},
    comments: {
      paginated: {},
      unpaginated: [],
    },
    people: {
      paginated: {},
      unpaginated: [],
    },
    contacts: {
      paginated: {},
      unpaginated: [],
    },
    purchase: {
      paginated: {},
      unpaginated: [],
    },
    sponsors: {
      paginated: {},
      unpaginated: [],
    },
    organizer: {
      paginated: {},
      unpaginated: [],
    },
    faqs: {
      paginated: {},
      unpaginated: [],
    },
    photo: {
      paginated: {},
      unpaginated: [],
    },
    entities: {
      paginated: {},
      unpaginated: [],
    },
    entitiesByType: {},
    iframes: {
      paginated: {},
      unpaginated: [],
    },
    questions: {
      paginated: {},
      unpaginated: [],
    },
    products: {
      paginated: {},
      unpaginated: [],
    },
    challenges: {
      paginated: {},
      unpaginated: [],
    },
    projects: {
      paginated: {},
      unpaginated: [],
    },
    elementsKeysList: {
      paginated: {},
      unpaginated: [],
    },
    addon: {
      paginated: {},
      unpaginated: [],
    },
    ideas: {
      paginated: {},
      unpaginated: [],
    },
    jobs: {
      paginated: {},
      unpaginated: [],
    },
    jobApplicants: {
      paginated: {},
      unpaginated: [],
    },
    communityEvent: {
      paginated: {},
      unpaginated: [],
    },
    currentChallengeIdeas: {
      paginated: {},
      unpaginated: [],
    },
    chatRooms: {},
    currentForumAnswers: {
      paginated: {},
      unpaginated: [],
    },
    forumAnswers: {
      paginated: {},
      unpaginated: [],
    },
    classifiers: {},
    classifiersType: {
      paginated: {},
      unpaginated: [],
    },
    classifiersBO: [],
    classifiersTree: {},
    customFields: {
      paginated: {},
      unpaginated: [],
    },
    customFieldsBO: {
      paginated: {},
      unpaginated: [],
    },
    storage: {
      paginated: {},
      unpaginated: [],
    },
    communityMembersByType: {
      paginated: {},
      unpaginated: [],
    },
    membersByType: {},
    forums: {},
    blogs: {
      paginated: {},
      unpaginated: [],
    },
    successstories: {
      paginated: {},
      unpaginated: [],
    },
    apps: {},
    services: {
      paginated: {},
      unpaginated: [],
    },
    ticket: {
      paginated: {},
      unpaginated: [],
    },
    tickets: {
      paginated: {},
      unpaginated: [],
    },
    subscriptionPlans: {
      paginated: {},
      unpaginated: [],
    },
    roles: {
      paginated: {},
      unpaginated: [],
    },
    memberRoles: {
      paginated: {},
      unpaginated: [],
    },
    media: {},
    grants: {
      paginated: {},
      unpaginated: [],
    },
    articles: {},
    emails: {
      paginated: {},
      unpaginated: [],
    },
    faqsBO: {
      paginated: {},
      unpaginated: [],
    },
    membersBO: {
      paginated: {},
      unpaginated: [],
    },
    legalBO: {
      paginated: {},
      unpaginated: [],
    },
    authImage: {
      paginated: {},
      unpaginated: [],
    },
    agenda: {
      paginated: {},
      unpaginated: [],
    },
    addons: {
      paginated: {},
      unpaginated: [],
    },
    integrations: {
      paginated: {},
      unpaginated: [],
    },
    appStore: [],
    GDPRAccepted: true,
    welcome: {
      paginated: {},
      unpaginated: [],
    },
    floors: {
      paginated: {},
      unpaginated: [],
    },
    mainData: {
      paginated: {},
      unpaginated: [],
    },
    types: {},
    statuses: {},
    loading: true,
    analytics: [],
    overview: [],
    analyticsSeries: [],
    organizationSales: [],
    analyticsGA: [],
    analyticsRangeGA: [],
    analyticsLastMinute: [],
    modelTypes: [],
    feedback: {
      paginated: {},
      unpaginated: [],
    },
    votes: {
      paginated: {},
      unpaginated: [],
    },
    votesAnswers: {
      paginated: {},
      unpaginated: [],
    },
    mountedSocket: {},
    organizationReferrals: {
      paginated: {},
      unpaginated: [],
    },
    appRoom: {
      paginated: {},
      unpaginated: [],
    },
    onboardingAnswers: {
      paginated: {},
      unpaginated: [],
    },
    elements: {
      paginated: {},
      unpaginated: [],
    },
    coupons: {
      paginated: {},
      unpaginated: [],
    },
    subtypes: {
      paginated: {},
      unpaginated: [],
    },
    permissions: {
      paginated: {},
      unpaginated: [],
    },
    installedApps: [],
    organizationPayments: {
      paginated: {},
      unpaginated: [],
    },
    attendance: {
      paginated: {},
      unpaginated: [],
    },
    connectedMembers: {
      paginated: {},
      unpaginated: [],
    },
    layoutTemplates: {
      paginated: {},
      unpaginated: [],
    },
    myMemberRelationshipsUsingConnects: {
      paginated: {},
      unpaginated: [],
    },
    communities: {
      paginated: {},
      unpaginated: [],
    },
    spaces: {
      paginated: {},
      unpaginated: [],
    },
    shareOrganizations: {
      paginated: {},
      unpaginated: [],
    },
    backofficeTemplates: {
      paginated: {},
      unpaginated: [],
    },
    countries: {
      paginated: {},
      unpaginated: [],
    },
    childCommunities: {
      paginated: {},
      unpaginated: [],
    },
    presencialRoomEditingMode: false,
    membersError: '',
    tasks: {
      paginated: {},
      unpaginated: [],
    },
    share: {
      paginated: {},
      unpaginated: [],
    },
    selectedFloor: null,
    selectedSpace: null,
    selectedRow: null,
    cart: [],
    selectedSeat: null,
    tokenVerification: [],
    organizationsFilter: [],
  },
  getters: {
    // TODO: implement a single getter for standard calls
    events: (state) => state.events,
    cart: (state) => state.cart,
    selectedFloor: (state) => state.selectedFloor,
    selectedSpace: (state) => state.selectedSpace,
    selectedRow: (state) => state.selectedRow,
    selectedSeat: (state) => state.selectedSeat,
    presencialRoomEditingMode: (state) => state.presencialRoomEditingMode,
    floors: (state) => state.floors,
    forms: (state) => state.forms,
    tickets: (state) => state.tickets,
    formQuestions: (state) => state.formQuestions,
    rooms: (state) => state.rooms,
    parentRooms: (state) => state.parentRooms,
    posts: (state) => state.posts,
    presentation: (state) => state.presentation,
    communityMembers: (state) => state.communityMembers,
    communityLikes: (state) => state.communityLikes,
    completedItems:(state) => state.completedItems,
    completableElements:(state) => state.completableElements,
    memberRelation: (state) => state.memberRelation,
    childCommunities: (state) => state.childCommunities,
    myMemberRelationships: (state) => state.myMemberRelationships,
    myMemberRelationshipsUsingConnects: (state) => state.myMemberRelationshipsUsingConnects,
    organizations: (state) => state.organizations,
    comments: (state) => state.comments,
    people: (state) => state.people,
    sponsors: (state) => state.sponsors,
    communitiesOthers: (state) => state.communitiesOthers,
    organizer: (state) => state.organizer,
    faqs: (state) => state.faqs,
    photo: (state) => state.photo,
    elementsKeysList: (state) => state.elementsKeysList,
    addon: (state) => state.addon,
    sections: (state) => state.sections,
    agenda: (state) => state.agenda,
    entities: (state) => state.entities,
    sharedCommunities: (state) => state.sharedCommunities,
    entitiesByType: (state) => state.entitiesByType,
    iframes: (state) => state.iframes,
    questions: (state) => state.questions,
    purchase: (state) => state.purchase,
    products: (state) => state.products,
    product: (state) => (key) => state.products.unpaginated.find((product) => product.key === key),
    challenges: (state) => state.challenges,
    communityEvent: (state) => state.communityEvent,
    item: (state) => (type, key) => state[type].unpaginated.find((item) => item.key === key),
    itemNoPage: (state) => (type, key) => state[type].find((item) => item.key === key),
    fragmentedItem: (state) => (type, key, storedKey) => state[type][storedKey].unpaginated.find((item) => item.key === key),
    projects: (state) => state.projects,
    getOfSame: (state) => state.getOfSame,
    ideas: (state) => state.ideas,
    jobs: (state) => state.jobs,
    jobApplicants: (state) => state.jobApplicants,
    articles: (state) => state.articles,
    classifiers: (state) => state.classifiers,
    classifiersType: (state) => state.classifiersType,
    classifiersBO: (state) => state.classifiersBO,
    classifiersTree: (state) => state.classifiersTree,
    customFields: (state) => state.customFields,
    storage: (state) => state.storage,
    currentChallengeIdeas: (state) => state.currentChallengeIdeas,
    currentForumAnswers: (state) => state.currentForumAnswers,
    forumAnswers: (state) => state.forumAnswers,
    forums: (state) => state.forums,
    blogs: (state) => state.blogs,
    communityMembersByType: (state) => state.communityMembersByType,
    successstories: (state) => state.successstories,
    announcements: (state) => state.announcements,
    media: (state) => state.media,
    availabilityRules: (state) => state.availabilityRules,
    membersByType: (state) => state.membersByType,
    apps: (state) => state.apps,
    services: (state) => state.services,
    organizationMembers: (state) => state.organizationMembers,
    service: (state) => (key) => state.services.unpaginated.find((service) => service.key === key),
    ticket: (state) => state.ticket,
    chatRooms: (state) => state.chatRooms,
    subscriptionPlans: (state) => state.subscriptionPlans,
    forms: (state) => state.forms,
    subscriptions: (state) => state.subscriptions,
    payments: (state) => state.payments,
    transactions: (state) => state.transactions,
    shipments: (state) => state.shipments,
    invoices: (state) => state.invoices,
    roles: (state) => state.roles,
    memberRoles: (state) => state.memberRoles,
    analytics: (state) => state.analytics,
    analyticsGA: (state) => state.analyticsGA,
    analyticsRangeGA: (state) => state.analyticsRangeGA,
    analyticsSeries: (state) => state.analyticsSeries,
    organizationSales: (state) => state.organizationSales,
    grants: (state) => state.grants,
    analyticsLastMinute: (state) => state.analyticsLastMinute,
    overview: (state) => state.overview,
    types: (state) => state.types,
    statuses: (state) => state.statuses,
    modelTypes: (state) => state.modelTypes,
    grant: (state) => (key) => state.grants.unpaginated.find((grant) => grant.key === key),
    feedback: (state) => state.feedback,
    emails: (state) => state.emails,
    faqsBO: (state) => state.faqsBO,
    legalBO: (state) => state.legalBO,
    authImage: (state) => state.authImage,
    membersBO: (state) => state.membersBO,
    addons: (state) => state.addons,
    integrations: (state) => state.integrations,
    appStore: (state) => state.appStore,
    welcome: (state) => state.welcome,
    votes: (state) => state.votes,
    votesAnswers: (state) => state.votesAnswers,
    organizationReferrals: (state) => state.organizationReferrals,
    appRoom: (state) => state.appRoom,
    onboardingAnswers: (state) => state.onboardingAnswers,
    elements: (state) => state.elements,
    coupons: (state) => state.coupons,
    mainData: (state) => state.mainData,
    subtypes: (state) => state.subtypes,
    installedApps: (state) => state.installedApps,
    permissions: (state) => state.permissions,
    organizationPayments: (state) => state.organizationPayments,
    customFieldsBO: (state) => state.customFieldsBO,
    attendance: (state) => state.attendance,
    connectedMembers: (state) => state.connectedMembers,
    layoutTemplates: (state) => state.layoutTemplates,
    communities: (state) => state.communities,
    spaces: (state) => state.spaces,
    shareOrganizations: (state) => state.shareOrganizations,
    backofficeTemplates: (state) => state.backofficeTemplates,
    countries: (state) => state.countries,
    membersError: (state) => state.MembersError,
    tasks: (state) => state.tasks,
    tokenVerification: (state) => state.tokenVerification,
    contacts: (state) => state.contacts,
    organizationsFilter: (state) => state.organizationsFilter,
    share: (state) => state.share,

  },
  mutations: {
    SET_ITEM_LITERAL(state, payload) {
      try {
        Vue.set(state, payload.itemType, payload.data);
      } catch (e) {
        console.log(e);
        state[payload.itemType] = payload.data;
      }
    },
    SET_ITEMS(state, payload) {
      // console.log('state', state);
      // console.log('payload', payload);
      const toItem = payload.customName ?? payload.type;
      // console.log('toItem', toItem);
      if (!payload.data.meta) {
        // State of unpaginated server data
        if (payload.storedKey == null) {
          state[toItem] = payload.data;
        } else {
          state[toItem][payload.storedKey] = payload.data;
        }
      } else {
        // State of paginated server data
        // eslint-disable-next-line no-lonely-if
        if (payload.storedKey == null) {
          // En caso de que no sea un fragmentado guardaremos el paginado y el no paginado junto al meta.
          Vue.set(
            state[toItem].paginated,
            payload.data.meta.current_page ?? 1,
            [...payload.data.data],
          );
          // Aquí comprobamos si han forzado una llamada a API para no tener valores repetidos.
          state[toItem].unpaginated = payload.forceAPICall
            ? [...payload.data.data]
            : [...state[toItem].unpaginated, ...payload.data.data];
          Vue.set(state[toItem], 'meta', { ...payload.data.meta });
        } else {
          // console.log('state[toItem]', state[toItem]);
          // console.log('payload.storedKey', payload.storedKey);
          // console.log('state[toItem][payload.storedKey]', state[toItem][payload.storedKey]);
          // En caso de que sea un fragmentado comprobamos si existe.
          if (state[toItem][payload.storedKey] == null) {
            // En caso de no existir se inicializa.
            Vue.set(state[toItem], payload.storedKey, {
              paginated: {},
              unpaginated: {},
            });
          }
          // En caso de existir no hace falta inicializarlo, sigue adelante.
          Vue.set(
            state[toItem][payload.storedKey].paginated,
            payload.data.meta.current_page ?? 1,
            [...payload.data.data],
          );
          // Exactamente lo mismo que antes pero para el unpaginated
          if (state[toItem][payload.storedKey].unpaginated.length == null) {
            state[toItem][payload.storedKey].unpaginated = [
              ...payload.data.data,
            ];
          } else {
            state[toItem][payload.storedKey].unpaginated = payload.forceAPICall
              ? [...payload.data.data]
              : [
                ...state[toItem][payload.storedKey].unpaginated,
                ...payload.data.data,
              ];
          }
          Vue.set(state[toItem][payload.storedKey], 'meta', {
            ...payload.data.meta,
          });
        }
      }
    },
    SET_ORGANIZATIONS_FILTER(state, payload) {
      state.organizationsFilter = payload;
    },
    SET_CLASSIFIERS(state, payload) {
      state[payload.type] = payload.data;
    },
    SET_CLASSIFIERSTREE(state, payload) {
      state[payload.type] = payload.data;
    },
    ADD_ITEM(state, payload) {
      // console.log('ADD_ITEM state', state);
      const toAdd = payload.customName != null ? payload.customName : payload.itemType;
      // console.log('ADD_ITEM toAdd', toAdd);
      const data = payload.data.data == null ? payload.data : payload.data.data;
      if (payload.orderByDate === 1) {
        if (Array.isArray(data)) {
          for (const row of data) {
            if (payload.storedKey != null) {
              state[toAdd][payload.storedKey].unpaginated = [
                ...state[toAdd][payload.storedKey].unpaginated,
                row,
              ];
            } else {
              state[toAdd].unpaginated = [...state[toAdd].unpaginated, data];
            }
          }
        } else if (payload.storedKey != null) {
          if (!state[toAdd][payload.storedKey]) {
            state[toAdd][payload.storedKey] = {
              unpaginated: [],
              paginated: {},
            };
          }
          state[toAdd][payload.storedKey].unpaginated = [
            ...state[toAdd][payload.storedKey].unpaginated,
            payload.data,
          ];
        } else if (data == null) {
          state[toAdd].unpaginated = [
            ...state[toAdd].unpaginated,
            payload.data,
          ];
        } else {
          state[toAdd].unpaginated = [...state[toAdd].unpaginated, data];
        }
      } else if (Array.isArray(data)) {
        for (const row of data) {
          if (payload.storedKey != null) {
            state[toAdd][payload.storedKey].unpaginated = [
              row,
              ...state[toAdd][payload.storedKey].unpaginated,
            ];
          } else {
            state[toAdd].unpaginated = [row, ...state[toAdd].unpaginated];
          }
        }
      } else if (payload.storedKey != null) {
        if (!state[toAdd][payload.storedKey]) {
          state[toAdd][payload.storedKey] = {
            unpaginated: [],
            paginated: {},
          };
        }
        state[toAdd][payload.storedKey].unpaginated = [
          payload.data,
          ...state[toAdd][payload.storedKey].unpaginated,
        ];
      } else if (data == null) {
        state[toAdd].unpaginated = [payload.data, ...state[toAdd].unpaginated];
      } else {
        state[toAdd].unpaginated = [data, ...state[toAdd].unpaginated];
      }
      /* state[payload.itemType].meta.total++; */
    },
    DELETE_ORGANIZATION(state, payload) {
      let index;
      if (payload.storedKey != null) {
        index = state[payload.itemType][payload.slug].unpaginated
          .map((element) => element.key)
          .indexOf(payload.key);
        state[payload.itemType][payload.storedKey].unpaginated.splice(index, 1);
      } else {
        index = state[payload.itemType][payload.slug].unpaginated
          .map((element) => element.key)
          .indexOf(payload.key);

        state[payload.itemType][payload.slug].unpaginated = state[
          payload.itemType
        ][payload.slug].unpaginated.filter(
          (element) => element.key !== payload.key,
        );
      }
      /* state[payload.itemType].meta.total++; */
    },

    DELETE_ITEM(state, payload) {
      let index;
      let toDelete = payload.customName != null ? payload.customName : payload.itemType;
      if(!toDelete){
        return;
      } else if(toDelete === 'tenders'){
        toDelete = 'grants';
        payload.storedKey = null;
      }
      let stateFromStoredKeyToDelete = null;
      if (payload.storedKey) {
        // console.log('payload', payload);
        stateFromStoredKeyToDelete = state[toDelete][payload.storedKey];
        console.log('stateFromStoredKeyToDelete', stateFromStoredKeyToDelete);
        if(stateFromStoredKeyToDelete){
          index = stateFromStoredKeyToDelete.unpaginated
            .map((element) => element.key)
            .indexOf(payload.key);
          stateFromStoredKeyToDelete.unpaginated.splice(index, 1);
          --stateFromStoredKeyToDelete.meta.total;
        }
      } else {
        index = state[toDelete].unpaginated
          .map((element) => element.key)
          .indexOf(payload.key);
        state[toDelete].unpaginated = state[toDelete].unpaginated.filter(
          (element) => element.key !== payload.key,
        );
        --state[toDelete].meta.total;
      }

      // state[payload.itemType].meta.total++;
    },
    DELETE_MEMBER(state, payload) {
      let index;
      if (payload.storedKey != null) {
        index = state[payload.itemType][payload.storedKey].unpaginated
          .map((element) => element.key)
          .indexOf(payload.key);
        delete state[payload.itemType][payload.storedKey].unpaginated[index];
      } else {
        index = state[payload.itemType].unpaginated
          .map((element) => element.key)
          .indexOf(payload.key);
        state[payload.itemType].unpaginated.splice(index, 1);

        state[payload.itemType].unpaginated.push(payload.data);
      }
    },
    EDIT_ITEM(state, payload) {
      const toAdd = payload.customName != null ? payload.customName : payload.itemType;

      if (payload.storedKey != null) {
        const indexToModify = state[toAdd][
          payload.storedKey
        ].unpaginated.findIndex((x) => x.key == payload.data.key);
        state[toAdd][payload.storedKey].unpaginated[indexToModify] = payload.data;
      } else {
        const indexToModify = state[toAdd].unpaginated.findIndex(
          (x) => x.key == payload.data.key,
        );
        state[toAdd].unpaginated[indexToModify] = payload.data;
      }
    },
    /**
     * This mutation is used just to hack/fix the current imposibility of fetching an item at the time.
     */
    ADD_UNPAGINATED_ITEMS(state, { type, items, prepend }) {
      const storedItemKeys = state[type].unpaginated.map(({ key }) => key);
      const newItems = items.filter(({ key }) => !storedItemKeys.includes(key));
      if (prepend) {
        state[type].unpaginated.unshift(...newItems);
      } else {
        state[type].unpaginated.push(...newItems);
      }
    },
    UPDATE_ITEM(state, { itemType, itemKey, partialItem }) {
      const unpaginatedIndex = state[itemType].unpaginated.findIndex(
        ({ key }) => key === itemKey,
      );
      if (unpaginatedIndex >= 0) {
        Vue.set(state[itemType].unpaginated, unpaginatedIndex, {
          ...state[itemType].unpaginated[unpaginatedIndex],
          ...partialItem,
        });
      }

      Object.entries(state[itemType].paginated).some(([page, items]) => {
        const index = items.findIndex(({ key }) => key === itemKey);
        if (index >= 0) {
          Vue.set(state[itemType].paginated[page], index, {
            ...state[itemType].paginated[page][index],
            ...partialItem,
          });

          return true;
        }

        return false;
      });
    },
    ADD_FEEDBACK(state, payload) {
      if (payload.type === 'new-suggestion') {
        state.feedback.push(payload.data);
      } else if (payload.type === 'add-answer') {
        const x = state.feedback.unpaginated.findIndex(
          (item) => item.key === payload.data.key,
        );
        state.feedback[x] = payload.data;
      } else {
        state.feedback = payload.data;
      }
    },
    ADD_ROLES(state, payload) {
      state.roles.unpaginated = payload;
    },
    UPDATE_ROLES(state, payload) {
      state.roles.unpaginated.push(payload);
    },
    ADD_MEMBER_ROLES(state, payload) {
      state.memberRoles.unpaginated = payload;
    },
    ADD_ANALYTICS_LAST_MINUTE(state, payload) {
      state.analyticsLastMinute.slice(0, 14);
      if (state.analyticsLastMinute.length === 15) {
        state.analyticsLastMinute.shift();
      }
      state.analyticsLastMinute.push(payload);
    },
    ADD_ANALYTICS(state, payload) {
      state.analytics = payload;
    },
    ADD_OVERVIEW(state, payload) {
      state.overview = payload;
    },
    ADD_INSTALLED_APPS(state, payload) {
      state.installedApps = payload;
    },
    ADD_CLASSIFIERS_TYPE(state, payload) {
      state.classifiersType = payload;
    },
    ADD_MODEL_TYPES(state, payload) {
      state.modelTypes = payload;
    },
    ADD_ANALYTICS_GA(state, payload) {
      state.analyticsGA = payload;
    },
    ADD_CLASSIFIERS_BO(state, payload) {
      state.classifiersBO = payload;
    },
    ADD_RANGE_ANALYTICS_GA(state, payload) {
      state.analyticsRangeGA = payload;
    },
    ADD_ANALYTICS_SERIES(state, payload) {
      state.analyticsSeries = payload;
    },
    ADD_PAYMENTS(state, payload) {
      state.organizationSales = payload;
    },
    ADD_CONNECTED_MEMBERS(state, payload) {
      state.connectedMembers = payload;
    },
    ADD_COMMUNITY_LIKES(state, payload) {
      state.communityLikes = payload;
    },
    ADD_COMMUNITY_COMPLETES(state, payload) {
      state.completedItems = payload;
    },
    ADD_COMMUNITY_COMPLETABLE_ELEMENTS(state, payload) {
      state.completableElements = payload;
    },
    UNSHARE_CLASSIFIERS_BO(state, payload) {
      const index = state.classifiersBO
        .map((element) => element.key)
        .indexOf(payload.classifierKey);
      state.classifiersBO.splice(index, 1);
      state.classifiersType.unpaginated.find(
        (item) => item.key === payload.key,
      ).classifiers -= 1;
    },
    DELETE_CLASSIFIERS_TYPE_BO(state, payload) {
      const index = state.classifiersType
        .map((element) => element.key)
        .indexOf(payload.key);
      state.classifiersType.splice(index, 1);
      state.classifiersType.unpaginated.find(
        (item) => item.key === payload.key,
      ).classifierTypes -= 1;
    },
    setError(state, membersError) {
      state.membersError = membersError;
    },
  },
  actions: {
    getItemsNode(
      { commit, state, rootState },
      {
        itemType,
        key,
        morphType,
        eventKey,
        collectiveKey,
        page,
        perPage,
        requestConfig,
        orderByCreationTime,
        forceAPICall,
        peopleCount,
      },
    ) {
      return new Promise((resolve) => {
        const workspace = itemType === 'sections'
          ? rootState.collective.parentcollective.slug
          : rootState.collective.collective.slug;

        let nextPage = page;
        if (!nextPage) {
          // If page is not specified load next page (like infinite scroll)
          nextPage = state[itemType].meta
            ? state[itemType].meta.current_page + 1
            : 1;
        } else if (
          state[itemType].paginated != null
          && state[itemType].paginated[page]
          && !forceAPICall
        ) {
          // If the page requested is already in the state (when going back to a view or pagination)
          const dataFromCache = {
            data: state[itemType].paginated[page],
            meta: state[itemType].meta,
          };
          resolve(dataFromCache);
          return;
        }

        const workspaceParam = itemType === 'sections' ? 'communityParentSlug' : 'communitySlug';
        $services[Service.BackendClient]
          .get(`/${itemType}`, {
            params: {
              [workspaceParam]: workspace,
              peopleCount,
              page: page || nextPage,
              key,
              morphType,
              eventKey,
              collectiveKey,
              count: perPage,
              orderByCreationTime,
              ...requestConfig,
            },
          })
          .then((response) => {
            commit('SET_ITEMS', { 
              type: itemType, 
              data: response.data 
            });
            resolve(response.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    getItems(
      { commit, state, rootState },
      {
        itemType,
        key,
        morphType,
        eventKey,
        collectiveKey,
        page,
        perPage,
        requestConfig,
        productKey,
        customName,
        storedKey,
        forceAPICall,
        communitySlug,
        communityKey,
        keyNeeded,
        noSet,
      },
    ) {
      return new Promise((resolve) => {
        if (page == null) {
          page = 1;
        }

        const spaceSlug = communitySlug || rootState.collective.collective.slug;
        const toItem = customName != null ? customName : itemType;
        let nextPage = page;
        // Workaround people PROD

        if (requestConfig == null) {
          requestConfig = {};
        }
        
        if (communityKey) {
          requestConfig.communityKey = communityKey;
        } else if (requestConfig.communitySlug == null) {
          requestConfig.communitySlug = spaceSlug;
        }

        if (communityKey == null && keyNeeded) {
          return;
        }

        if (itemType === 'people' && page !== 1 && requestConfig.count > 50) {
          return;
        }

        // If page is not specified load next page (like infinite scroll)
        if (!nextPage) {
          nextPage = state[toItem] != null && state[toItem].meta
            ? state[toItem].meta.current_page + 1
            : 1;
        }

        // If the page requested is already in the state (when going back to a view or pagination)
        if (
          state[toItem]
          && state[toItem].paginated
          && state[toItem].paginated[page]
          && !forceAPICall
        ) {
          resolve();
          return;
        }
        
        if (
          storedKey != null
          && state[toItem]
          && state[toItem][storedKey] != null
          && state[toItem][storedKey].paginated
          && state[toItem][storedKey].paginated[page]
          && !forceAPICall
        ) {
          resolve();
          return;
        }

        //console.log('copernicData.store getItems... itemType:', itemType);
        // console.log('Item (' + itemType + ') not found in FE store, calling BE...');

        // Hacemos la llamada a Backend
        $services[Service.BackendClient]
          .get(`/${itemType}`, {
            params: {
              ...(itemType == 'organization/payments' && !page ? '' : { page } || { nextPage }),
              key,
              morphType,
              eventKey,
              productKey,
              collectiveKey,
              count: perPage,
              ...requestConfig,
            },
          })
          .then((response) => {
            if (!noSet) {
              commit('SET_ITEMS', {
                type: itemType,
                storedKey,
                customName,
                forceAPICall,
                data: response.data,
              });
            }
            resolve(response.data);
          })
          .catch((e) => {
            if (e.response && !noSet) {
              commit('SET_ITEMS', {
                type: itemType,
                storedKey,
                customName,
                forceAPICall,
                data: e.response?.data,
              });
            }
            console.log(e);
            resolve(e.response?.data);
          });
      });
    },
    async toggleLike(
      { state, rootState },
      {
        key, itemType, morphType, storedKey, customName,
      },
    ) {
      let index;
      let item;
      let query;
      const itemName = customName || itemType;
      const communityKey = rootState.collective.collective.key;

      if (storedKey != null) {
        //TODO: arreglar cuando unpaginated es undefined
        index = state[itemName][storedKey].unpaginated.findIndex(
          (x) => x.key === key,
        );

        item = state[itemName][storedKey].unpaginated[index];
        query = item.likedByMember ? 'takeLike' : 'giveLike';
      } else if (itemName === 'communitiesOthers') {
        index = state.communitiesOthers[morphType].unpaginated.findIndex(
          (x) => x.key === key,
        );

        item = state.communitiesOthers[morphType].unpaginated[index];

        query = item.likedByMember ? 'takeLike' : 'giveLike';
        morphType = 'community';
      } else {
        index = state[itemName].unpaginated.findIndex((x) => x.key === key);
        item = state[itemName].unpaginated[index];
        query = item.likedByMember ? 'takeLike' : 'giveLike';
      }
      try {
        await $services[Service.BackendClient].post(
          query,
          { key, morphType, communityKey },
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
        );
        if (storedKey != null) {
          Vue.set(state[itemName][storedKey].unpaginated, index, {
            ...item,
            likedByMember: !item.likedByMember,
            totalLikes:
              item.totalLikes
              + (morphType !== 'post' && morphType !== 'questions'
                ? item.likedByMember
                  ? -1
                  : +1
                : 0),
          });
        } else if (itemName === 'communitiesOthers') {
          Vue.set(state.communitiesOthers.challenges.unpaginated, index, {
            ...item,
            likedByMember: !item.likedByMember,
            totalLikes:
              item.totalLikes
              + (morphType !== 'post' && morphType !== 'questions'
                ? item.likedByMember
                  ? -1
                  : +1
                : 0),
          });
        } else {
          Vue.set(state[itemName].unpaginated, index, {
            ...item,
            likedByMember: !item.likedByMember,
            totalLikes:
              item.totalLikes
              + (morphType !== 'post' && morphType !== 'questions'
                ? item.likedByMember
                  ? -1
                  : +1
                : 0),
          });
        }
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
      }
    },
    async toggleCompleted({ commit, state, rootState }, { morphType, key, itemCompleted }) {
      const communityKey = rootState.collective.collective.key;
      const query = itemCompleted ? 'takeCompleted' : 'giveCompleted';
      try {
        await $services[Service.BackendClient]
          .post(
            query,
            { key, morphType, communityKey },
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
          )
          .then((response) => {
            //Emit state:
            index = state['completedItems'].unpaginated.findIndex((x) => x.key === key);
            Vue.set(state['completedItems'].unpaginated, key, {
              key: key,
              morphType: morphType,
              communityKey: communityKey,
              completedByMember: !item.completedByMember
              // totalCompletes:
              //   item.totalCompletes
              //   + (item.completedByMember ? -1 : +1),
            });
            // console.log('state:', state);
            // console.log('rootState:', rootState);
            // console.log('Commit ADD_COMMUNITY_COMPLETES with response:', response.data);
            commit('ADD_COMMUNITY_COMPLETES', response.data);
            // console.log('state:', state);
            // console.log('rootState:', rootState);
            resolve(response);
          });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
      }
    },
    async UpdateFromSocket(
      { state, rootState, commit },
      {
        key, itemType, customName, storedKey, data,
      },
    ) {
      let item;
      let index;

      const toItem = customName != null ? customName : itemType;

      if (storedKey != null) {
        index = state[toItem][storedKey].unpaginated.findIndex(
          (x) => x.key === key,
        );
        item = state[toItem][storedKey].unpaginated[index];
      } else {
        index = state[toItem].unpaginated.findIndex((x) => x.key === key);
        item = state[toItem].unpaginated[index];
      }

      for (const key in data) {
        if (data[key].AddToExistent === true) {
          item[key] = [...item[key], data[key]];
        } else if (data[key] === '++') {
          item[key] += 1;
        } else if (data[key] === '--') {
          item[key] -= 1;
        } else {
          item[key] = data[key];
        }
      }

      commit('EDIT_ITEM', {
        itemType,
        customName,
        storedKey,
        data: item,
      });
    },
    async getComments({ state }, {
      key, morphType, orderByDate, page,
    }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('comments', {
            params: {
              key,
              morphType,
              orderByDate,
              page,
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    async postComment({ state, rootState }, { key, morphType, comment }) {
      const communityKey = rootState.collective.parentcollective.key
        || rootState.collective.collective.key;

      try {
        await $services[Service.BackendClient].post(
          'comments',
          {
            key,
            morphType,
            comment,
            communityKey,
          },
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
        );
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
      }
    },
    postItem(
      {
        state, commit, rootState, rootGetters,
      },
      {
        type, requestConfig, forceUpdate, customName, storedKey, inMain,
      },
    ) {
      return new Promise((resolve) => {
        const communitySlug = inMain
          ? rootGetters.mainCollective.slug
          : rootState.collective.collective.slug;
        $services[Service.BackendClient]
          .post(
            type,
            {
              communitySlug,
              ...requestConfig,
            },
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
          )
          .then((response) => {
            if (forceUpdate) {
              commit('DELETE_ITEM', {
                itemType: customName || type,
                key: requestConfig.key,
                storedKey,
              });
            }
            resolve(response);
          });
      });
    },
    async createQuestion({ rootState, commit, dispatch }, { question }) {
      const workspace = rootState.collective.collective.slug;
      try {
        const response = await $services[Service.BackendClient].post(
          'createQuestion',
          {
            communitySlug: workspace,
            // TODO: Dynamize language
            name: { es: question.name },
            answerType: 'text',
            rules: 'required',
          },
          { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
        );
        dispatch('addAnalytic', {
          morphType: 'question',
          action: 'create',
          content: response,
        });

        // commit("ADD_ITEM", { itemType: "questions", data: response.data });

        return response;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async unshareItem(
      { commit, rootState },
      {
        item, customName, communitySlug, noSet,
      },
    ) {
      const community = communitySlug || rootState.collective.collective.slug;
      let response;
      try {
        const requestData = {
          communitySlug: community,
          ...item,
        };
        response = await $services[Service.BackendClient].post(
          `unshare${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );
        if (!noSet) {
          commit('DELETE_ITEM', {
            itemType: item.itemType,
            key: item.key,
            storedKey: item.storedKey,
            customName,
          });
        } else {
          return response;
        }
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async unshareIdea({ commit, rootState }, { item }) {
      const community = rootState.collective.collective.slug;
      let response;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item,
        };
        response = await $services[Service.BackendClient].post(
          `unshare${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        commit('DELETE_ITEM', { itemType: 'ideas', key: item.key });
      } catch (error) {
        console.log(error);
      }
    },
    // PROVISIONAL
    async unshareForum({ commit, rootState }, { item }) {
      const community = rootState.collective.collective.slug;
      let response;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item,
        };
        response = await $services[Service.BackendClient].post(
          `${item.itemType}` + '/unshare',
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );
        if (item.itemType === 'forumAnswers') {
          commit('DELETE_ITEM', {
            itemType: item.itemType,
            storedKey: item.storedKey,
            answerKey: item.answerKey,
            forumKey: item.forumKey,
          });
        } else {
          commit('DELETE_ITEM', {
            itemType: item.itemType,
            storedKey: item.storedKey,
            key: item.forumKey,
          });
        }
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteItemGeneric(
      { commit, rootState },
      { item, customName, communitySlug },
    ) {
      const community = communitySlug || rootState.collective.collective.slug;
      let response;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item,
        };
        response = await $services[Service.BackendClient].post(
          'deleteItem',
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        console.log('Deleting item...');

        const payload = {
          itemType: customName || item.tableName,
          key: item.modelKey,
          storedKey: item.storedKey,
          customName,
        };
        commit('DELETE_ITEM', payload);
        console.log('Deleted!');
        return true;
      } catch (error) {
        console.log('error', error)
        // if (error.indexOf('40') || error.indexOf('50')) {
        //   return;
        // }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async unshareModel(
      { commit, rootState },
      { item, customName, communitySlug },
    ) {
      const community = communitySlug || rootState.collective.collective.slug;
      let response;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item,
        };
        response = await $services[Service.BackendClient].post(
          'unshareModel',
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        commit('DELETE_ITEM', {
          itemType: customName || item.tableName,
          key: item.modelKey,
          storedKey: item.storedKey,
          customName,
        });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async unshareProject({ commit, rootState }, { item, type, tableName }) {
      const community = rootState.collective.collective.slug;
      let response;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item,
        };
        response = await $services[Service.BackendClient].post(
          `unshare${type}`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        commit('DELETE_ITEM', { itemType: tableName, key: item.key });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteItems(
      { dispatch, commit, rootState },
      { item, type, forceUpdate },
    ) {
      const community = rootState.collective.collective.slug;
      let response;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item,
        };
        response = await $services[Service.BackendClient].put(
          `delete${type}`,
          requestData,
          {},
        );

        // commit('DELETE_ITEM', { itemType: item.tableName, storedKey: 'questions', key: item.key });
      } catch (error) {
        console.log(error);
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteRooms(
      { dispatch, commit, rootState },
      { item, type, forceUpdate },
    ) {
      const community = rootState.collective.collective.slug;
      let response;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          roomKey: item.key,
        };
        response = await $services[Service.BackendClient].post(
          `${type}`,
          requestData,
          {},
        );

        commit('DELETE_ITEM', { itemType: item.tableName, key: item.key });
      } catch (error) {
        console.log(error);
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async createItem(
      {
        commit, rootState, rootGetters, dispatch,
      },
      {
        item, file, noSet, inMain, communitySlug,
      },
    ) {
      const community = communitySlug
        || (inMain
          ? rootGetters.mainCollective.slug
          : rootState.collective.collective.slug);
      try {
        let requestData;
        if (item.itemType === 'products' || item.itemType === 'services') {
          // Pendiente de eliminar
          requestData = {
            communitySlug: community,
            modelType: 'community',
            modelKey: rootState.collective.collective.key,
            ...item.requestConfig,
          };
        } else {
          requestData = {
            communitySlug: community,
            // TODO: move challengeKey to requestConfig
            // Pendiente de eliminar
            challengeKey: item.challengeKey,
            // TODO: Dynamize language
            ...item.requestConfig,
          };
        }

        let response;

        // Para poder generalizarlo todo, me dedicare a utilizar el formData como comodín para mandar los datos. Puede ser para el multipart o un JSON.
        let formData;
        // Selecciono el header de la request según el caso de uso. Así me ahorro los if-else
        const selectedHeader = file != null && Object.values(file).length > 0
          ? { 'content-type': 'multipart/form-data' }
          : { 'Content-Type': 'application/x-www-form-urlencoded' };
        if (file != null && Object.values(file).length > 0) {
          // En caso de que me manden file el formData hará la preparación de los archivos
          formData = new FormData();
          // La nueva funcionalidad del backend permite diferenciar los archivos según su nombre del parametro. En caso de utilizar este metodo (File.type es null)
          // se ha de tratar de forma dinamica.
          if (file.type == null) {
            for (const row in file) {
              formData.append(row, file[row]);
            }
          } else {
            // En caso de utilizar el metodo antiguo (file es el archivo) ha de montarlo simple
            formData.append('file', file);
          }
          formData.append('data', JSON.stringify(requestData));
        } else {
          // En caso de que no exista ningún archivo adjunto para mandar el formData será el JSON de requestData
          formData = requestData;
        }

        // En vez de tener 2 POST como antes ahora tengo solo 1
        response = await $services[Service.BackendClient].post(
          item.itemType,
          formData,
          { headers: selectedHeader },
        );
        dispatch('addAnalytic', {
          morphType: item.itemType,
          action: 'create',
          content: response,
        });

        // TODO: improve this workaround
        if (!response.data.ok) {
          if (!noSet) {
            const addItemData = {
              itemType: item.itemType,
              storedKey: item.storedKey,
              customName: item.customName,
              data: response.data,
              orderByDate: item.orderByDate,
            };
            // console.log('addItemData', addItemData)
            commit('ADD_ITEM', addItemData);
          }
        }
        return response;
      } catch (error) {
        //console.log('createItem error', error);
        if (error.response.status < 500) {
          commit('setError', error.response.data);
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },

    async editItem(
      { dispatch, commit, rootState },
      {
        item, noSet, file, forceUpdate, communitySlug, method,
      },
    ) {
      const community = communitySlug || rootState.collective.collective.slug;
      try {
        const requestData = {
          communitySlug: community,
          challengeKey: item.challengeKey || '',
          // TODO: Dynamize language
          ...item.requestConfig,
        };
        let response;

        if (file && Object.values(file).length > 0) {
          const formData = new FormData();
          if (file.type == null) {
            for (const row in file) {
              formData.append(row, file[row]);
            }
          } else {
            formData.append('file', file);
          }
          formData.append('data', JSON.stringify(requestData));

          if (method === 'post') {
            response = await $services[Service.BackendClient].post(
              item.itemType,
              formData,
              {
                headers: { 'content-type': 'multipart/form-data' },
              },
            );
          } else {
            response = await $services[Service.BackendClient].put(
              item.itemType,
              formData,
              {
                headers: { 'content-type': 'multipart/form-data' },
              },
            );
          }
        } else if (method === 'post') {
          response = await $services[Service.BackendClient].post(
            item.itemType,
            requestData,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          );
        } else {
          response = await $services[Service.BackendClient].put(
            item.itemType,
            requestData,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          );
        }
        if (forceUpdate) {
          await dispatch('getItems', {
            itemType: item.customName ? item.customName : item.itemType,
            requestConfig: requestData,
            forceAPICall: true,
          });
        } else {
          if (item.itemType === 'question') {
            item.itemType = 'questions';
          }
          if (item.itemType === 'answer') {
            return;
          }
          if (!noSet) {
            commit('EDIT_ITEM', {
              itemType: item.itemType,
              customName: item.customName,
              storedKey: item.storedKey,
              data: response.data,
            });
          }

          dispatch('addAnalytic', {
            morphType: item.itemType,
            action: 'update',
            content: response,
          });
          return response;
        }
        dispatch('addAnalytic', {
          morphType: item.itemType,
          action: 'update',
          content: response,
        });
        return response.data;
      } catch (error) {
        commit('setError', error.response.data);

        if (error.response.status < 500) {
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteQuestion({ commit, rootState }, { item }) {
      const community = rootState.collective.collective.slug;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item.requestConfig,
        };
        let response;
        response = await $services[Service.BackendClient].post(
          `delete${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );
        if (item.itemType === 'question') {
          item.itemType = 'questions';
        }
        commit('DELETE_ITEM', {
          itemType: item.itemType,
          key: item.requestConfig.key,
        });
      } catch (error) {
        if (error.response.status < 500) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteOrganization({ commit, rootState }, { item }) {
      const community = rootState.collective.collective.slug;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item.requestConfig,
        };
        let response;
        response = await $services[Service.BackendClient].post(
          `delete${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );
        commit('DELETE_ORGANIZATION', {
          itemType: item.itemType,
          key: item.requestConfig.organizationKey,
          slug: community,
        });
      } catch (error) {
        console.log(error);
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteItem({ commit, rootState }, { item, noSet }) {
      const community = rootState.collective.collective.slug;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item.requestConfig,
        };
        let response;

        response = await $services[Service.BackendClient].post(
          `delete${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        if (!noSet) {
          commit('DELETE_MEMBER', {
            itemType: item.itemType,
            storedKey: item.storedKey,
            key: requestData.memberKeys[1],
            data: response.data,
          });
        }
        return response;
      } catch (error) {
        if (error.response.status < 500) {
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    // DELETE COMMENT PROVISIONAL
    async deleteEvent({ commit, rootState }, { item, customName }) {
      const community = rootState.collective.collective.slug;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item.requestConfig,
        };
        let response;
        response = await $services[Service.BackendClient].post(
          `delete${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        commit('DELETE_ITEM', {
          itemType: item.itemType,
          key: item.requestConfig.key,
          customName: 'events',
        });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteComment({ commit, rootState }, { item, customName }) {
      const community = rootState.collective.collective.slug;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item.requestConfig,
        };
        let response;
        response = await $services[Service.BackendClient].post(
          `delete${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        commit('DELETE_ITEM', { itemType: item.itemType, key: requestData.key, storedKey: item.storedKey });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteMedia({ commit, rootState }, { item, customName }) {
      const community = rootState.collective.collective.slug;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item.requestConfig,
        };
        let response;
        response = await $services[Service.BackendClient].post(
          `delete${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );

        commit('DELETE_ITEM', {
          itemType: item.customName || item.itemType,
          key: item.requestConfig.mediaKey,
          storedKey: item.requestConfig.mediaType,
        });
      } catch (error) {
        console.error('error', error);
      }
    },
    async deleteBlog({ commit, rootState }, { item, customName }) {
      const community = rootState.collective.collective.slug;
      try {
        let requestData;
        requestData = {
          communitySlug: community,
          ...item.requestConfig,
        };
        let response;
        response = await $services[Service.BackendClient].post(
          `delete${item.itemType[0].toUpperCase() + item.itemType.substring(1)
          }`,
          requestData,
          {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          },
        );
        commit('DELETE_ITEM', { itemType: item.itemType, key: item.key });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async deleteMember({ commit }, { itemType, requestConfig }) {
      commit('DELETE_ITEM', { itemType, key: requestConfig.key });
    },
    async AddClassifiers(
      { commit },
      {
        morphKey, morphType, classifierKey, items,
      },
    ) {
      try {
        const response = await $services[Service.BackendClient].put(
          'classifiers',
          {
            morphKey,
            morphType,
            classifierKey,
          },
        );
        commit('SET_CLASSIFIERS', {
          type: 'organizationsClassifiers',
          data: response.data,
        });
        this.isLoading = false;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },

    async fetchCustomFields({ commit, rootState }, { modelType, communitySlug }) {
      if(!communitySlug){
        communitySlug = rootState.collective.collective.slug || rootState.collective.parentcollective.slug;
      } 
      //console.log('fetchCustomFields communitySlug', communitySlug);
      if(!communitySlug){
        return;
      }
      try {
        const response = await $services[Service.BackendClient].get(
          '/customfield',
          {
            params: {
              communitySlug,
              modelType,
            },
          },
        );
        commit('SET_CLASSIFIERS', {
          type: 'customFields',
          data: response.data.data,
        });
        this.isLoading = false;
      } catch (error) {
        console.log('error', error);
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },

    async AddCustomFields(
      { commit, rootState },
      {
        modelKey, modelType, customKey, customField, noSet,
      },
    ) {
      const communitySlug = rootState.collective.parentcollective.slug
        || rootState.collective.collective.slug;
      try {
        const response = await $services[Service.BackendClient].put(
          'customfield',
          {
            modelKey,
            communitySlug,
            modelType,
            customKey,
            customField,
          },
        );
        if (!noSet) {
          commit('SET_CLASSIFIERS', {
            type: 'customFields',
            data: response.data,
          });
        }
        this.isLoading = false;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },

    async fetchOnboardingResponses({ commit, rootState }, { memberKey }) {
      const communitySlug = rootState.collective.parentcollective.slug
        || rootState.collective.collective.slug;
      try {
        const response = await $services[Service.BackendClient].get(
          '/onboardingResponses',
          {
            params: {
              communitySlug,
              memberKey,
            },
          },
        );
        commit('SET_CLASSIFIERS', {
          type: 'customFields',
          data: response.data.data,
        });
        this.isLoading = false;
      } catch (error) {
        console.log('error', error)
        if (typeof error === 'string' && (error.indexOf('40') !== -1 || error.indexOf('50') !== -1)) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },

    async changeTaglineApp(
      { commit, rootState },
      {
        appID, appKey, tagline, customization, deleteCustomizationName,
      },
    ) {
      const communitySlug = rootState.collective.collective.slug;
      try {
        const response = await $services[Service.BackendClient].post(
          'apptagline',
          {
            appID,
            appKey,
            tagline,
            communitySlug,
            customization,
            deleteCustomizationName,
          },
        );
        commit('SET_CLASSIFIERS', {
          type: 'customFields',
          data: response.data,
        });
        this.isLoading = false;
      } catch (error) {
        if (error.response.status < 500) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    getItemsPayments(
      { commit, state, rootState },
      {
        itemType, eventKey, ticketKey, requestConfig,
      },
    ) {
      return new Promise((resolve) => {
        const workspace = requestConfig.slug || rootState.collective.collective.slug;
        $services[Service.Payment]
          .get(`/api/${itemType}`, {
            params: {
              communitySlug: workspace,
              eventKey,
              ticketKey,
              ...requestConfig,
            },
          })
          .then((response) => {
            commit('SET_ITEMS', {
              type: itemType,
              data: response.data,
              forceAPICall: true,
            });
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    async createItemPayments(
      { rootState, commit, dispatch },
      { itemType, item, noSet },
    ) {
      try {
        const response = await $services[Service.Payment].post(
          `/api/v1/${itemType}`,
          {
            communitySlug: rootState.collective.collective.slug,
            ...item,
          },
        );
        dispatch('addAnalytic', {
          morphType: itemType,
          action: 'create',
          content: response,
        });
        if (!noSet) {
          commit('ADD_ITEM', { itemType, data: response.data });
        } else {
          return response;
        }
      } catch (error) {
        if (error.response.status < 500) {
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async processPayment({ dispatch, getters, rootGetters }, { itemType, data }) {
      try {
        const response = await $services[Service.Payment].post(
          `/api/${itemType}`,
          {
            communitySlug: rootGetters.currentCollective.slug,
            ...data,
          },
        );
        return response.data;
      } catch (error) {
        if (error.response.status < 500) {
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async editItemsPayments(
      { commit, rootState, dispatch },
      { itemType, item, communitySlug },
    ) {
      try {
        const response = await $services[Service.Payment].put(
          `/api/v1/${itemType}`,
          {
            communitySlug:
              communitySlug || rootState.collective.collective.slug,
            ...item,
          },
        );
        commit('EDIT_ITEM', {
          itemType,
          customName: item.customName,
          storedKey: item.storedKey,
          data: response.data,
        });
        dispatch('addAnalytic', {
          morphType: itemType,
          action: 'update',
          content: response,
        });
      } catch (error) {
        if (error.response.status < 500) {
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async unshareItemPayments({ commit, rootState }, { itemType, item }) {
      try {
        await $services[Service.Payment].post(
          `/api/v1/delete${itemType[0].toUpperCase() + itemType.substring(1)}`,
          {
            communitySlug: rootState.collective.collective.slug,
            ...item,
          },
        );
        commit('DELETE_ITEM', { itemType, key: item.key });
      } catch (error) {
        if (error.response.status < 500) {
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    getOrganizationPayments({ commit }, { organizationKey }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('/organizationPayments', {
            params: { organizationKey },
          })
          .then((response) => {
            commit('ADD_PAYMENTS', response.data);
            resolve(response.data);
          })
          .catch(Bugsnag.notify);
      });
    },

    async importFiles(
      { rootState, commit, dispatch },
      {
        importType,
        file,
        forceUpdate,
        forceInsert,
        customName,
        storedKey,
        listingType,
        classifierTypeKey,
        requestConfig,
      },
    ) {
      const requestData = {
        communitySlug: rootState.collective.collective.slug,
        ...(classifierTypeKey !== '' ? { classifierTypeKey } : ''),
        ...(forceInsert ? { forceInsert } : ''),
        ...requestConfig,
      };
      try {
        const formData = new FormData();
        if (file.type == null) {
          for (const row in file) {
            formData.append(row, file[row]);
          }
        } else {
          formData.append('file', file);
        }
        formData.append('data', JSON.stringify(requestData));

        const response = await $services[Service.Imports].post(
          importType,
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
        if (forceUpdate) {
          const requestSpace = { // Sólo organizations spaces
            page: 1,
            isOrganization: true,
            communityParentSlug: rootState?.collective?.parentCollective?.slug
              || rootState?.collective.collective?.slug,
            importedMainType: 9,

          };
          const request = {
            page: 1,
            orderByName: 1,
            isForBackoffice: 1,
            ...(listingType !== '' ? { listingType } : ''),
            ...(classifierTypeKey !== '' ? { classifierTypeKey } : ''),
          };
          await dispatch('getItems', {
            itemType: customName,
            ...(customName === 'communities/simply' ? { customName: 'communitiesOthers' } : customName ? { customName } : ''),
            ...(storedKey ? { storedKey } : ''),
            ...{ requestConfig: customName === 'communities/simply' ? requestSpace : request },
            forceAPICall: true,
          });
        } else if (customName !== 'classifiers') {
          const dataMembers = response.data.map((item) => item.member);
          commit('ADD_ITEM', { itemType: customName, data: dataMembers });
        }
      } catch (error) {
        if (error.response.status < 500) {
          return error.response;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async exportFiles({ rootState, commit, dispatch }, { itemType, mainTypeID, requestConfig }) {
      try {
        const response = await $services[Service.Imports].get(itemType, {
          params: {
            communitySlug: rootState.collective.collective.slug,
            mainTypeID,
            ...requestConfig,
          },
        });
        return response;
      } catch (error) {
        Bugsnag.notify(error);
        if (error.response?.status < 500) {
          return error.response;
        }
        throw new Error(500);
      }
    },
    async exportFilesExtended(
      { rootState, commit, dispatch },
      { itemType, params },
    ) {
      try {
        const response = await $services[Service.Imports].get(itemType, {
          params: {
            communitySlug: rootState.collective.collective.slug,
            startDate: new Date(params.start).toISOString(),
            endDate: params.end != null ? new Date(params.end).toISOString() : null,
          },
        });
        return response;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getRoles(
      { commit, rootGetters },
      {
        memberKey, page, perPage, namespace,
      },
    ) {
      const communityData = {
        communityKey: rootGetters.currentCollective.key,
      };
      const response = await $services[Service.BackendClient].get('roles', {
        params: {
          ...communityData,
          ...(memberKey ? { memberKey } : ''),
          ...(namespace ? { namespace } : ''),
          page,
          perPage,
        },
      });
      memberKey
        ? commit('ADD_MEMBER_ROLES', response.data.data)
        : commit('SET_ITEMS', {
          type: 'roles',
          data: response.data,
          forceAPICall: true,
        });

      return response.data;
    },
    async setRoles(
      { commit, rootGetters },
      { memberKey, roleKeys, deleteRoleKeys },
    ) {
      const communitySlug = {
        communityKey: rootGetters.currentCollective.key,
      };
      const response = await $services[Service.BackendClient].post('setRoles', {
        ...communitySlug,
        memberKey,
        roleKeys,
        deleteRoleKeys,
      });
      commit('ADD_MEMBER_ROLES', response.data.data);

      return response.data;
    },
    async editRoles(
      { commit, rootGetters, dispatch },
      {
        roleKey, name, tagName, description,
      },
    ) {
      const communitySlug = {
        communityKey: rootGetters.currentCollective.key,
      };
      const response = await $services[Service.BackendClient].put('roles', {
        ...communitySlug,
        roleKey,
        name,
        description,
        tagName,
      });
      dispatch('addAnalytic', {
        morphType: 'role',
        action: 'update',
        content: response,
      });
      return response.data;
    },
    createRoles({ rootGetters, dispatch }, { roles }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .post('createRoles', {
            communitySlug: rootGetters.currentCollective.slug,
            roles,
          })
          .then((response) => {
            dispatch('addAnalytic', {
              morphType: 'role',
              action: 'create',
              content: response,
            });
            resolve(response.data);
          })
          .catch();
      });
    },
    async getAnalyticsSummary({ commit, rootGetters }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .post('/counters', {
            counters: [
              {
                name: 'events-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
              },
              {
                name: 'event-duration-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
              },
              // {
              //   name: 'connected-users',
              //   classificators: {
              //     communityKey: rootGetters.currentCollective.key,
              //   },
              //   headerText: 'text/csv',
              // },
              {
                name: 'members-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
              },
              {
                name: 'members-joined-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
              },
              {
                name: 'members-left-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
              },
              {
                name: 'created-resources-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
              },
              {
                name: 'comments-modified-seen-in-community',
                classificators: {
                  seenInCommunityKey: rootGetters.currentCollective.key,
                },
              },
              {
                name: 'comments-seen-in-community',
                classificators: {
                  seenInCommunityKey: rootGetters.currentCollective.key,
                },
              },
              {
                name: 'likes-seen-in-community',
                classificators: {
                  seenInCommunityKey: rootGetters.currentCollective.key,
                },
              },
            ],
          })
          .then((response) => {
            commit('ADD_ANALYTICS', response.data.counters);
            resolve(response.data.counters);
          })
          .catch(Bugsnag.notify);
      });
    },
    // To get the metrics in csv
    async getSeriesCounters({ commit, rootGetters }, { start, end, step }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .post('/counterSeries', {
            counters: [
              {
                name: 'events-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'event-duration-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'members-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'members-joined-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'members-left-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'created-resources-per-community',
                classificators: {
                  communityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'comments-modified-seen-in-community',
                classificators: {
                  seenInCommunityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'comments-seen-in-community',
                classificators: {
                  seenInCommunityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
              {
                name: 'likes-seen-in-community',
                classificators: {
                  seenInCommunityKey: rootGetters.currentCollective.key,
                },
                headerText: 'text/csv',
              },
            ],
            start,
            end,
            step,
          })
          .then((response) => {
            commit('ADD_ANALYTICS_SERIES', response.data.series);
            resolve(response.data.series);
          })
          .catch(Bugsnag.notify);
      });
    },
    async sendEmail({ rootGetters }, infoSended) {
      try {
        await $services[Service.BackendClient].post('sendMail', {
          communityKey: rootGetters.currentCollective.key,
          ...infoSended,
        });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getPublicAddons({ commit }) {
      return new Promise((resolve) => {
        $services[Service.Public].get(
          '/addons',
          {
            headers: {
              Authorization: `Bearer ${process.env.VUE_APP_API_PUBLIC_API_KEY}`,
            },
          },
        ).then((response) => {
          commit('SET_ITEMS', {
            type: 'appStore',
            data: response.data.data,
          });
          resolve(response.data.data);
        }).catch((error) => {
          if (error.response) {
            commit('SET_ITEMS', {
              type: 'appStore',
              data: error.response?.data,
            });
          }
          console.log(error);
          resolve(error.response?.data);
        });
      });
    },
    async getAnalyticsGA({ commit, rootGetters }, { num }) {
      try {
        const response = await $services[Service.BackendClient].get(
          '/googleAnalyticsRealtime',
          {
            params: {
              communitySlug: rootGetters.currentCollective.slug,
            },
          },
        );
        commit('ADD_ANALYTICS_GA', response.data);
        commit('ADD_ANALYTICS_LAST_MINUTE', num); // Last Minute

        return response.data;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getConnectedMembers({ commit, rootGetters }) {
      try {
        const response = await $services[Service.BackendClient].get(
          '/onlineAttendees',
          {
            params: {
              communitySlug: rootGetters.currentCollective.slug,
            },
          },
        );
        commit('ADD_CONNECTED_MEMBERS', response.data);

        return response.data;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getCommunityLikes({ commit, rootGetters }) {
      try {
        const response = await $services[Service.BackendClient].get('/likes', {
          params: {
            communitySlug: rootGetters.currentCollective.slug,
          },
        });
        commit('ADD_COMMUNITY_LIKES', response.data);

        return response.data;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getOverview({ commit, rootGetters }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('/backoffice/overview', {
            params: {
              communitySlug: rootGetters.currentCollective.slug,
            },
          })
          .then((response) => {
            commit('ADD_OVERVIEW', response.data);
            resolve(response.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    async getInstalledApps({ commit, rootGetters }, getInstalledApps) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('/backoffice/addonsInstalled', {
            params: {
              communitySlug: rootGetters.currentCollective.slug,
              ...getInstalledApps,
            },
          })
          .then((response) => {
            commit('ADD_INSTALLED_APPS', response.data);
            resolve(response.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    async getClassifiersType({ commit, rootGetters }, { page, perPage }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('/backoffice/classifierTypes', {
            params: {
              communityKey: rootGetters.currentCollective.key,
              page,
              perPage,
              count: 1000,
            },
          })
          .then((response) => {
            commit('ADD_CLASSIFIERS_TYPE', response.data.data);
            resolve(response.data.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    async editClassifiersType({ commit, rootGetters }, { classifier }) {
      try {
        const response = await $services[Service.BackendClient].put(
          '/backoffice/classifierTypes',
          {
            communityKey: rootGetters.currentCollective.key,
            ...classifier,
          },
        );
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getModelTypes({ commit, rootState }) {
      // console.log('Getting model types...');
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('backoffice/addonsInstalled', {
            params: {
              communitySlug: rootState.collective.collective.slug,
            },
          })
          .then((response) => {
            commit('ADD_MODEL_TYPES', response.data.data);
            resolve(response.data.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    async addClassifiersBO(
      { commit, rootGetters },
      { name, classifierTypeKey },
    ) {
      try {
        await $services[Service.BackendClient].post('/backoffice/classifiers', {
          communityKey: rootGetters.currentCollective.key,
          name,
          classifierTypeKey,
        });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async editClassifiersBO({ commit, rootGetters }, { name, classifierKey }) {
      try {
        await $services[Service.BackendClient].put('/backoffice/classifiers', {
          communityKey: rootGetters.currentCollective.key,
          name,
          classifierKey,
        });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getClassifiersBO({ commit, rootGetters }, { key }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('/backoffice/classifiers', {
            params: {
              communityKey: rootGetters.currentCollective.key,
              count: 5000,
              classifierTypeKey: key,
              isForBackoffice: true,
            },
          })
          .then((response) => {
            commit('ADD_CLASSIFIERS_BO', response.data.data);
            resolve(response.data.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    unshareClassifierBO(
      { commit, rootGetters },
      { type, customName, requestConfig },
    ) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .post('backoffice/classifiers/delete', {
            classifierKey: requestConfig.classifierKey,
            communitySlug: rootGetters.currentCollective.slug,
          })
          .then((response) => {
            commit('UNSHARE_CLASSIFIERS_BO', requestConfig);
            resolve(response.data.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    deleteClassifierTypeBO(
      { commit, rootGetters },
      { type, customName, requestConfig },
    ) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .post('backoffice/classifierTypes/delete', {
            classifierTypeKey: requestConfig.classifierTypeKey,
            communitySlug: requestConfig.ccommunitySlug ?? rootGetters.currentCollective.slug,
          })
          .then((response) => {
            console.log('response', response);
            console.log('commit DELETE_CLASSIFIERS_TYPE_BO', requestConfig);
            commit('DELETE_CLASSIFIERS_TYPE_BO', requestConfig);
            console.log('resolve', response.data.data);
            resolve(response.data.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    async getRangeAnalyticsGA({ commit, rootGetters }, { startDate, endDate }) {
      try {
        const response = await $services[Service.BackendClient].get(
          '/googleAnalytics',
          {
            params: {
              communitySlug: rootGetters.currentCollective.slug,
              startDate,
              endDate,
            },
          },
        );
        commit('ADD_RANGE_ANALYTICS_GA', response.data);

        return response.data;
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async changePassword(
      {
        dispatch, commit, getters, rootGetters, state,
      },
      { requestConfig },
    ) {
      try {
        const communitySlug = rootGetters.currentCollective.slug;
        requestConfig.communitySlug = communitySlug;

        const response = await $services[Service.BackendClient].post(
          'changePassword',
          {
            ...requestConfig,
          },
        );

        return response;
      } catch (error) { }
    },
    async attachRolePermissions({ rootGetters }, { roleKey, permissions }) {
      try {
        await $services[Service.BackendClient].post('attachRolePermissions', {
          communityKey: rootGetters.currentCollective.key,
          roleKey,
          permissions,
        });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async dettachRolePermissions({ rootGetters }, { roleKey, permissions }) {
      try {
        await $services[Service.BackendClient].post('dettachRolePermissions', {
          communityKey: rootGetters.currentCollective.key,
          roleKey,
          permissions,
        });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async getFeedback({ commit, rootGetters }) {
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('/backoffice/feedback', {
            params: {
              communitySlug: rootGetters.currentCollective.slug,
              // classifierTypeKey: key,
            },
          })
          .then((response) => {
            commit('ADD_FEEDBACK', { data: response.data.data });
            resolve(response.data.data);
          })
          .catch(Bugsnag.notify);
      });
    },
    async addSuggestion({ commit, rootGetters }, { message, type, status }) {
      let response = '';
      try {
        response = await $services[Service.BackendClient].post(
          '/backoffice/feedback',
          {
            communitySlug: rootGetters.currentCollective.slug,
            message,
            type,
            status,
          },
        );
        commit('ADD_FEEDBACK', { data: response.data, type: 'new-suggestion' });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async addSuggestionAnswer(
      { commit, rootGetters },
      { status, answer, suggestionKey },
    ) {
      let response = '';
      try {
        response = await $services[Service.BackendClient].put(
          '/backoffice/feedback',
          {
            communitySlug: rootGetters.currentCollective.slug,
            status,
            answer,
            suggestionKey,
          },
        );
        commit('ADD_FEEDBACK', { data: response.data, type: 'add-answer' });
      } catch (error) {
        console.log('error', error)
        if (error.indexOf('40') || error.indexOf('50')) {
          return;
        }

        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async inviteMembers({ commit, rootGetters }, { emails }) {
      try {
        const response = await $services[Service.BackendClient].post(
          '/backoffice/inviteMembers',
          {
            communitySlug: rootGetters.currentCollective.slug,
            emails,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        );
        return response;
      } catch (error) {
        console.log('error', error)
        if (error.response.status === 403) {
          return error.response;
        }
        if (error.indexOf('40') || error.indexOf('50')) {
          return error;
        }
        Bugsnag.notify(error);
        throw new Error(500);
      }
    },
    async addPeople({ commit, rootState }, { images, settings }) {
      const community = rootState?.collective.collective?.slug
        || rootState?.collective?.parentCollective.slug;
      const requestData = {
        communitySlug: community,
        ...settings,
      };
      let response;
      if (images.imageURL || images.logo_url) {
        const formData = new FormData();
        if (images.imageURL != null) {
          formData.append('imageURL', images.imageURL);
        }
        if (images.organizationLogoURL != null) {
          formData.append('organizationLogoURL', images.organizationLogoURL);
        }
        formData.append('data', JSON.stringify(requestData));
        response = await $services[Service.BackendClient].post(
          '/people',
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
      } else {
        try {
          response = await $services[Service.BackendClient].post('/people', {
            communitySlug: community,
            ...settings,
          });
        } catch (e) { }
      }
      // response.data.isStaff = true;
      // const communityType = rootState.collective?.collective.name
      //   ? 'setCurrentCollective'
      //   : 'setCurrentParentCollective';
      // commit(communityType, response.data);
      return response.data;
    },
    async editPeople({ commit, rootState }, { images, settings, peopleKey }) {
      const community = rootState?.collective.collective?.slug
        || rootState?.collective?.parentCollective.slug;
      const requestData = {
        communitySlug: community,
        ...settings,
        peopleKey,
      };
      let response;
      if (images.imageURL || images.logo_url) {
        const formData = new FormData();
        if (images.imageURL != null) {
          formData.append('imageURL', images.imageURL);
        }
        if (images.organizationLogoURL != null) {
          formData.append('organizationLogoURL', images.organizationLogoURL);
        }
        formData.append('data', JSON.stringify(requestData));
        response = await $services[Service.BackendClient].put(
          '/people',
          formData,
          {
            headers: { 'content-type': 'multipart/form-data' },
          },
        );
      } else {
        try {
          response = await $services[Service.BackendClient].put('/people', {
            communitySlug: community,
            ...settings,
          });
        } catch (e) { }
      }
    },
    async emailSents(
      {
        dispatch, commit, getters, rootGetters, state,
      },
      { key },
    ) {
      try {
        const communitySlug = rootGetters.currentCollective.slug;

        const response = await $services[Service.BackendClient].get(
          '/myself/sent',
          {
            params: {
              messageKey: key != null ? key : undefined,
              communitySlug,
              orderByDate: -1,
            },
          },
        );

        for (const item of response.data.data) {
          const date = new Date(item.createdAt);
          item.createdAt = date.toLocaleString('es-ES');
        }

        commit('SET_ITEMS', {
          type: 'emails',
          data: response.data,
          forceAPICall: true,
        });
      } catch (error) { }
    },
    async inbox({
      dispatch, commit, getters, rootGetters, state,
    }, { key }) {
      try {
        const communitySlug = rootGetters.currentCollective.slug;

        const response = await $services[Service.BackendClient].get(
          '/myself/inbox',
          {
            params: {
              messageKey: key != null ? key : undefined,
              communitySlug,
              orderByDate: -1,
            },
          },
        );

        for (const item of response.data.data) {
          const date = new Date(item.createdAt);
          item.createdAt = date.toLocaleString('es-ES');
        }

        commit('SET_ITEMS', {
          type: 'emails',
          data: response.data,
          forceAPICall: true,
        });
        return response;
      } catch (error) { }
    },
    async toggleAddon(
      {
        dispatch, commit, getters, rootGetters, state,
      },
      { addonKey, type },
    ) {
      try {
        const communitySlug = rootGetters.currentCollective.slug;

        const response = await $services[Service.BackendClient].put(
          `${type}Addon`,
          {
            addonKey,
            communitySlug,
          },
        );

        return response;
      } catch (error) { }
    },
    async addAnalytic(
      {
        dispatch, commit, getters, rootGetters, state,
      },
      { action, morphType, content },
    ) {
      try {
        if (process.env.VUE_APP_ENV === 'development') {
          return;
        }
        const community = rootGetters.currentCollective;
        const asStaff = community.isStaff;

        const response = await $services[Service.BackendClient].post(
          'massiveLogs',
          {
            communitySlug: community.slug,
            action,
            morphType,
            asStaff,
            content,
          },
        );

        return response;
      } catch (error) { }
    },
    postZoom({ state, rootState }, { meetingNumber, role }) {
      return $services[Service.BackendClient].post(
        'zoomSignature',
        {
          meetingNumber,
          role,
        },
        // { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );
    },
    async mainApps({ rootGetters }) {
      const communitySlug = rootGetters.mainCollective.slug || rootGetters.currentCollective.slug;
      if (!communitySlug) {
        return;
      }
      if (communitySlug === rootGetters.currentCollective.slug) {
        return { data: rootGetters.apps };
      }
      return new Promise((resolve) => {
        $services[Service.BackendClient]
          .get('apps', {
            params: {
              communitySlug,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch(Bugsnag.notify);
      });
    },
  },
};
